<template>
  <div class="app-container industry-detail" v-loading="loading">
    <div class="industry-detail-main">
      <h4 class="main-title">{{industryDetails.Name}}</h4>
      <div class="main-content" v-if="industryDetails.Content" v-html="industryDetails.Content"></div>
    </div>
  </div>
</template>

<script>
  import API from '@/api/config'
  // import {IndustryInfo} from '@/api/career/industry'

  export default {
    name: "industry-detail",
    data() {
      return {
        loading: false,
        
        href: window.location.href.split('?id=')[1],

        industryDetails: '',
      }
    },

    created() {
      this.init(this.href)

    },

    methods: {
      init(id) {

        this.loading = true
        
        API.Career.IndustryInfo({Id: id}).then(res => {

          this.industryDetails = res.data

          this.loading = false

        }).catch(err =>{

          this.$message.error(err.message)
          this.loading = false
        })

      }
    }
  }
</script>

<style lang="less" scoped>
  // @import '~@/styles/base.scss';

  .industry-detail {
    width: 100%;
    min-height: 80vh;
    .industry-detail-main{
      width: 80%;
      margin: 0 auto;
      padding: 3rem 5rem;

      .main-title{
        font-size: 24px;
        text-align: center;
      }
      .main-content{
      }
    }

  }
</style>
